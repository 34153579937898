import {
	createRouter,
	createWebHistory
} from "vue-router";
// npm install vue-router@next --save
// {
// 		path: '/',
// 		name: 'home',
// 		component: ()=>import ('@/pages/home')
// 	},

// {
// 			path: '/',
// 			name: 'home',
// 			component: () => import('@/pages/home'),
// 			children: [{
// 				path: '/',
// 				name: '首页',
// 				component: () => import('@/pages/main/main.vue'),
// 				meta: {
// 					requireAuth: false
// 				}
// 			},{
// 				path: '/main',
// 				name: 'product',
// 				component: () => import('@/pages/main/main.vue'),
// 				meta: {
// 					requireAuth: false
// 				}
// 			}, {
// 				path: '/details',
// 				name: '关于',
// 				component: () => import('@/pages/home'),
// 				meta: {
// 					requireAuth: false
// 				}
// 			}]},
const routes = [
	{
			path: '/index.html',
			name: 'home',
			component: () => import('@/pages/home')
		},
	{
		path: '/',
		name: 'home',
		component: () => import('@/pages/home')
  },
  {
		path: '/look',
		name: 'look',
		component: () => import('@/pages/look/index.vue')
	},
	{
		path: '/details',
		name: 'details',
		component: () => import('@/pages/detail')
	},{
		path: '/mobile/details',
		name: 'mobileDetails',
		component: () => import('@/pages/detailMobileHome')
	},
	{
		path: '/userinfo',
		name: 'userinfo',
		component: () => import('@/pages/userinfo')
	},
	{
		path: '/login',
		name: 'login',
		component: () => import('@/pages/login')
	},
	{
		path: '/card',
		name: 'card',
		component: () => import('@/pages/card')
	},
	{
		path: '/notes',
		name: 'notes',
		component: () => import('@/pages/Notes')
	},
	{
		path: '/mobile/home',
		name: 'mobile',
		component: () => import('@/pages/mobile')
	},
	{
		path: '/mobile/test',
		name: 'testMobile',
		component: () => import('@/pages/mobile/testMobile')
	}
	
];
export const router = createRouter({
	history: createWebHistory(),
	routes: routes
});

export default router;
